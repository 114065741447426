<!--
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2019-11-21 19:42:31
 * @LastEditors: 贾峰
 * @LastEditTime: 2020-03-16 10:12:55 :style="isAndroid?'position:fixed;top:10vh;left:10vw':'position:absolute;top:20vh;left:10vw'"
 -->
<template>
  <div class="mainbody" id="mainpage" :style="{height: conheight+'px',minHeight:conheight+'px'}">
    <!-- 主页面 -->
      <div class="diabg" v-if="showdialog"></div>
      <div class="dialog" v-if="showdialog" >
        <div class="addfamins" :style="'background: url('+getStorage('ossurl','')+getStorage('imgs',{}).addjtbg+');background-size: 102% 100%'"><p>添加家庭信息</p></div>
        <p>请填写该家庭的名称</p>
        <input
          type="text"
          class="inputname"
          v-model="familyname"
          @focus="onfocus"
          placeholder="请输入家庭名称"
        />
        <md-button
          :style="'background:'+getStorage('theme','')"
          type="primary"
          :loading="submitnameloading"
          @click="savefamily"
          >保存</md-button
        >
        <img
          src="@/assets/abd/image/closedialog.png"
          class="closedialog"
          @click="closedialog"
        />
      </div>
      <!-- 可见区域 -->
      <div class="title">
        <div class="left" :style="'background: url('+getStorage('ossurl','')+getStorage('imgs',{}).jtnumbg+');background-size: 100% 100%;'">
          <div class="userleft">
            <p>家庭数量</p>
            <p>{{ customcount }}</p>
          </div>
          <div class="userright">
            <img src="@/assets/abd/image/SAAS-61.png" alt="" />
          </div>
        </div>
        <div class="right" @click="topolicy" :style="'background: url('+getStorage('ossurl','')+getStorage('imgs',{}).jtnumbg+');background-size: 100% 100%;'">
          <div class="policyleft">
            <p>
              保单数量<img
                src="@/assets/abd/image/pointer.png"
                style="width:10px"
              />
            </p>
            <p>{{ policycount }}</p>
          </div>
          <div class="policyright">
            <img
              src="@/assets/abd/image/SAAS-62.png"
              style="width:6vw;"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="body">
        <div class="bodytitle">
          <p>家庭列表</p>
          <img :src="getStorage('ossurl','')+getStorage('imgs',{}).refreshbtn" class="refreshbtn" @click="refreshfamilylist"/>
          <!--<img-->
            <!--src="@/assets/abd/image/main-refresh.png"-->
            <!--class="refreshbtn"-->
            <!--@click="refreshfamilylist"-->
          <!--/>-->
          <img src="@/assets/abd/image/SAAS-64.png" alt="" @click="showadd" />
        </div>
        <div class="bodylist">
        <md-scroll-view
          ref="scrollView"
          :scrolling-x="false"
          :auto-reflow="true"
          @refreshing="$_onRefresh"
          @end-reached="$_onEndReached">
           <md-scroll-view-refresh
            slot="refresh"
            slot-scope="{ scrollTop, isRefreshActive, isRefreshing }"
            :scroll-top="scrollTop"
            :is-refreshing="isRefreshing"
            :is-refresh-active="isRefreshActive"
            :refreshing-text="'拼命加载中...'"
            ></md-scroll-view-refresh>
           <div>
            <div class="list" v-for="i in famliyList" :key="i.familyno">
            <div class="listleft">
              <img src="@/assets/abd/image/SAAS-76.png" alt="" />
            </div>
            <div class="listright" @click="todetails(i.familyname, i.familyno)">
              <div>
                <div class="name">
                  <p>{{ i.familyname }}</p>
                  <div class="twopic">
                    <!-- <img
                      src="@/assets/abd/image/timg.jpg"
                      alt=""
                      @click.stop="showtwopic"
                      style="width:5vh;height:5vh"
                    /> -->

                    <span>{{ i.intime }}</span>
                  </div>
                </div>
              </div>
              <img
                src="@/assets/abd/image/SAAS-65.png"
                alt=""
                style="width:2vw;height:3vw"
              />
             </div>
            </div>
            </div>
           <md-scroll-view-more
            slot="more"
            :is-finished="isFinished"
           ></md-scroll-view-more>
       </md-scroll-view>
        </div>
      </div>
    </div>
</template>
<script>
import { Toast } from 'mand-mobile'
import config from '@/config'
import { getStorage, setStorage } from '@/lib/util'
import { wechatshare } from '@/lib/wechat_share'
import {
  famliyList,
  addFamliy,
  addfamilymember
} from '@/api/abd/family'
const { redirect_uri } = config
export default {
  data () {
    return {
      twovalue: 'https://example.com',
      twosize: 40,
      viewtwovalue: '',
      /* 二维码弹窗 */
      twopicshow: false,
      submitstatus: false,
      showedit: 'nopage',
      addform: {
        name: '',
        relation: '00',
        sex: '1',
        birthdate: '',
        cardtype: '',
        cardno: ''
      },
      isDatePickerShow: false,
      minDate: new Date('1940/1/1'),
      cardtype: '',
      cardSelectorShow: false,
      cardselectdata: [],
      cardnum: '',
      user: {},
      wxnum: '',
      wxcardShow: false,
      wxcarddata: [],
      submitnameloading: false,
      showdialog: false,
      famliyList: [
      ],
      showaddflag: false,
      familyname: '',
      customcount: '',
      policycount: '',
      winHeight: 0,
      // 列表分页
      page: 1,
      size: 50,
      total: 0,
      isFinished: false,

      // 判断从微信还是app进系统
      fromwhere: '',
      isAndroid: true,
      conheight: '',
      isPopupShow: true
    }
  },
  watch: {
    showaddflag (newval) {
      if (newval === false) this.familyname = ''
    }
  },
  beforeCreate () {
    Toast.loading('拼命加载中...')
  },
  created () {
    this.conheight = document.documentElement.clientHeight
    // this.conheight = window.innerHeight
    console.log(this.conheight)
    // 在页面整体加载完毕时
    window.onload = () => {
      // 把获取到的高赋值给mainbody
      document.getElementsByClassName('mainbody')[0].style.height = `${this.conheight}px`
      document.body.style.height = `${this.conheight}px`
    }
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      // ios
      this.isAndroid = false
    }
    wechatshare('', '', '', '', '', '', true)
    this.fromwhere = this.$route.query.fromwhere ? this.$route.query.fromwhere : ''
    setStorage('fromwhere', this.fromwhere)
    this.user = getStorage('u_s', {})
    /*  判断哪个跳过来,路由中如果有信息，就是客户扫二维码进来的，直接显示添加页面
       this.showedit = 'dialog',如果是业务员从个人中心或者从客户中心返回，this.showedit = 'main'
       Toast.loading('拼命加载中...')
       Toast.hide()
   */
    Toast.hide()
    this.getData(50, 1)
  },
  mounted () {
    window.ScrollViewTrigger1 = () => {
      this.$refs.scrollView.triggerRefresh()
    }
    this.conheight = document.documentElement.clientHeight
  },
  methods: {
    // 获取列表数据
    async getData (size, page) {
      await famliyList({ size, page }).then(res => {
        this.famliyList = res.data.data.list
        this.customcount = res.data.data.countFamily
        this.policycount = res.data.data.countFamilyPolicy
        this.total = res.data.data.countFamily
        this.famliyList.forEach(item => {
          // http://192.168.30.88:8080/
          item.value = `${redirect_uri}/bdtg?familyno=${item.familyno}&empno=${this.user.empno}`
        })
      })
    },
    // 下拉刷新
    $_onRefresh () {
      this.getData(50, 1)
      this.page = 1
      this.size = 50
      setTimeout(() => {
        this.$refs.scrollView.finishRefresh()
        this.isFinished = false
        this.$refs.scrollView.finishLoadMore()
      }, 1000)
    },
    async $_onEndReached () {
      if (this.isFinished) {
        return
      }
      let list = [...this.famliyList]
      this.page = this.page + 1
      if (this.total > (this.page - 1) * this.size && this.total < this.page * this.size) { await this.getData(this.size, this.page); this.famliyList = [...list, ...this.famliyList]; if (this.famliyList.length === this.total) this.isFinished = true } else if (this.page * this.size >= this.total) { this.isFinished = true; this.$refs.scrollView.finishLoadMore() } else { await this.getData(this.size, this.page); this.famliyList = [...list, ...this.famliyList]; if (this.famliyList.length === this.total) this.isFinished = true }
    },
    showadd (e) {
      e.stopPropagation()
      this.showdialog = true
    },
    savefamily () {
      this.submitnameloading = true
      addFamliy({ familyname: this.familyname })
        .then(res => {
          this.showdialog = false
          Toast.succeed('保存成功')
          this.getData(50, 1)
          this.familyname = ''
        })
        .finally(() => {
          this.submitnameloading = false
        })
    },
    todetails (name, no) {
      this.$router.push({
        path: '/customercenter',
        query: { familyname: name, familyno: no, fromwhere: this.fromwhere }
      })
    },
    topolicy () {
      this.$router.push({ path: '/analysis', query: { fromwhere: this.fromwhere } })
    },
    async refreshfamilylist () {
      Toast.loading('刷新中...')
      await this.getData(50, 1)
      Toast.succeed('刷新成功！')
    },
    closedialog () {
      this.showdialog = false
      this.familyname = ''
    },
    onfocus () {
      document.getElementsByClassName('mainbody')[0].style.height = `${this.conheight}px`
      document.body.style.height = `${this.conheight}px`
      console.log(document.getElementsByClassName('mainbody')[0].style.height)
      console.log(document.body.style.height)
      window.onresize = () => {
      // 把获取到的高赋值给mainbody
        document.getElementsByClassName('mainbody')[0].style.height = `${this.conheight}px`
        document.body.style.height = `${this.conheight}px`
        console.log('ok')
      }
    },
    /* 二维码添加弹窗 */
    onDatePickerConfirm (columnsValue) {
      this.addform.birthdate = this.$refs.datePicker2.getFormatDate(
        'yyyy-MM-dd'
      )
    },
    oncardtypeChoose ({ value, text }) {
      this.cardtype = text
      this.addform.cardtype = value
      let cardnum = this.addform.cardno
      if (cardnum.length === 10) {
        this.addform.birthdate =
          cardnum.slice(6, 10) +
          '-' +
          cardnum.slice(10, 12) +
          '-' +
          cardnum.slice(12, 14)
      }
    },
    inputcardnum () {
      let cardnum = this.addform.cardno
      if (cardnum.length === 18) {
        if (this.cardtype === '身份证') {
          this.addform.birthdate =
            cardnum.slice(6, 10) +
            '-' +
            cardnum.slice(10, 12) +
            '-' +
            cardnum.slice(12, 14)
        }
      }
    },
    onwxcardChoose ({ text }) {
      this.wxnum = text
    },
    showcardSelector (selectag) {
      this.cardSelectorShow = true
    },
    /* 提交添加内容 */
    submit () {
      this.submitstatus = true
      if (this.addform.name === '') {
        Toast.failed('姓名不能为空！')
        this.submitstatus = false
      } else {
        let jsonarr = []
        this.addform.empno = this.$route.query.empno
        this.addform.familyno = this.$route.query.familyno
        jsonarr.push(this.addform)

        addfamilymember({ abtComEmpnomembers: JSON.stringify(jsonarr) })
          .then(res => {
            Toast.succeed('添加成功')
            this.addform = {
              name: '',
              relation: '00',
              sex: '1',
              birthdate: '',
              cardtype: '',
              cardno: '',
              empno: '',
              familyno: ''
            }
            this.cardtype = ''
          })
          .finally(() => {
            this.submitstatus = false
          })
      }
    },
    // 二维码弹窗
    showtwopic (value) {
      this.twopicshow = true
      this.viewtwovalue = value
    }
  }
}
</script>
<style lang="stylus" scoped>
.md-button{
    background-color #00ADEF
}
.mainbody{
  background-color  #F7F6FB
  width 100vw
  height 100%
  overflow hidden
  position absolute 
}
    .title{
        display flex
        align-items center
        width 100vw
        height 20vh
        background-color white
        .left{
            display flex
            justify-content space-between
            align-items center
            width 44%
            padding 0 4%
            margin-left 4.5vw
            margin-right 1.5vw
            height 10vh
            background-color pink
            border-radius 10px
            /*dr-abd/image*/
            /*background: url('~@/assets/abd/image/SAAS-60.png') no-repeat;*/
            background-repeat: no-repeat;
            background-size: 100% 100%;
            .userleft{
                width 70%
                margin-left 10%
                p{
                    font-size 45px
                    color white
                }
                p:nth-child(1){
                    font-size 34px
                    color #F2F9FD
                    margin-bottom 1vw
                }
            }
            .userright{
                 width 50%
                 display flex
                 justify-content center
                 img{
                     width 7vw
                 }
            }
        }
        .right{
            display flex
            justify-content space-between
            align-items center
            border-radius 10px
            margin-left 1.5vw
            background-color orange
            width 44%
            padding 0 4%
            height 10vh
            /*dr-abd/image*/
            /*background: url('~@/assets/abd/image/SAAS-60.png') no-repeat;*/
            background-repeat: no-repeat;
            background-size: 100% 100%;
            .policyleft{
                width 70%
                margin-left 10%
                p{
                    font-size 45px
                    color white
                }
                p:nth-child(1){
                    display flex
                    align-items center
                    font-size 34px
                    color #F2F9FD
                    margin-bottom 1vw
                    img{
                        margin-left 15px
                    }
                }

            }
            .policyright{
                width 50%
                display flex
                justify-content center
            }
       }
    }
    .body{
        background-color white
        padding 0 4.5vw
        margin-top 20px
        .bodytitle{
            position relative
            height 12vh
            display flex
            justify-content space-between
            align-items center
            padding-top 30px
            border-bottom 1px solid #F7F6FB
            p{
                font-size 38px
                font-family 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
                font-weight bold
                color #4B4B4B
                letter-spacing:4px
            }
            .refreshbtn{
                width 14vw
                height 14vw
                position absolute
                left 38vw
            }
            img{
                width 5vw
                height 5vw
            }
        }
        .bodylist{
            height 65vh
            overflow auto
        }
        .list{
                display flex
                height 14vh
                align-items center
                border-bottom 1px solid #F4F4F4
                .listleft{
                    width 20vw
                    img{
                        width 15vw
                        height 15vw
                        border-radius 50%
                    }
                }
                .listright{
                    width 78vw
                    display flex
                    height 100%
                    justify-content space-between
                    align-items center
                    .name{
                        display flex
                        justify-content space-between
                        align-items center
                        width 68vw
                        height 13vh
                        p{
                          font-size 38px
                        }
                        .twopic{
                          display flex
                          justify-content space-between
                          align-items center
                          span{
                            color #C1C3C5
                            font-size 32px
                            margin-left 16px
                          }
                        }
                    }
                    .detail{
                        span{
                            color #757575
                            font-size 32px
                        }
                        span:nth-child(1){
                           margin-right 50px
                        }
                    }
                }
            }
    }
.diabg{
  position:absolute
  z-index 111
  background-color black
  opacity 0.6
  width 100vw
  height 100%
  overflow-y hidden
}
.dialog{
  position absolute
  width 80vw
  height 600px
  z-index 120
  border-radius 27px
  background-color white
  top 200px
  left 10vw
  .addfamins{
      display flex
      justify-content center
      height 200px
      width 100%
      border-radius 10px 23px 0 0
      /*background: url('~@/assets/abd/image/addfamname.png') no-repeat;*/
      background-size: 102% 100%;
      p{
        font-family:PingFang SC;
        color:rgba(255,255,255,1);
        font-size 56px
        letter-spacing:12px
      }
    }
   p{
      font-family:PingFang SC;
      color:rgba(117,117,117,1);
      padding 25px 8vw 20px
    }
    /deep/ .md-button{
        background-color color-primary
        height 100px
        border-radius 8px
        width 90%
        margin 20px auto
        color white
        display flex
        justify-content center
        align-items center
    }
   /deep/ .md-input-item-input{
        width 80%
        margin 0 10%
        background-color #F5F5F5
        font-size 35px
        padding 0 30px
    }
    .inputname{
        display block
        width 80%
        height 100px
        line-height 100px
        margin 0 10%
        background-color #F5F5F5
        font-size 35px
        padding 0 30px
        border-radius 10px
      }
       .inputname::-webkit-input-placeholder { /* WebKit browsers */
       color: #ced0d6;
       font-family:PingFang SC;
       color:rgba(157,157,157,1);
       font-weight:500;
       font-size 35px
    }
    .closedialog{
      position absolute
      z-index 121
      margin-top 200px
      width 80px
      height 80px
      left 36vw
     }
}
/* 弹出层 */
.addfamember{
    width 100vw
    height 100vh
    position relative
    background-color #F7F6FB
    .addfamily-title{
        height 8vh
        display flex
        align-items center
        background-color white
        padding 0  4vw
        justify-content space-between
        span{
            font-size 36px
            font-family 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
            font-weight bold
            color #4B4B4B
        }
    }
    .addfamily-body{

    }
      /deep/ .md-button{
        background-color color-primary
        height 6vh
        border-radius 8px
        width 90vw
        margin 0 5vw
        color white
        display flex
        justify-content center
        align-items center
        margin-bottom 4vh
    }

/deep/ .md-field-item-control {
  display: flex;
  justify-content: flex-end;
}
/deep/ .require {
  .md-field-item-title::after {
    content: '*';
    color: red;
  }
}
}
</style>
